@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');

// MY VARIABLES FOR GLOBAL USE

$prodFadeLt: #00D4FF;
$prodFadeDk: #04043E;
$prodTxt: #fff;
$myColor1: $prodFadeDk; //Themes the 'Body' background of site.
$myNavBar: $prodFadeDk; //Header and footer background color - good ones:  #3f51b5 #400CCC
$myNavDraw: $prodFadeDk; // background for mobile menu drawer
$myNavTxt: $prodTxt; // Header and footer text color
$myAdsBgd: #6999bb;
$ToTopColor: #00D4FF; //back-to-top button color
$myButtonBgd: #999; // buttons background colour
$myButtonTxt: #fff; // buttons txt color

$progress-width: 0; //for the AudioPlayer css :root equivalent
$buffered-width: 0; //for the AudioPlayer css :root equivalent

// IN-BUILT ITEMS STYLING

body {
    margin: 0;
    background: $myColor1;
    font-family: 'Nunito Sans',Helvetica,Arial,Lucida,sans-serif !important;
    -webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;    
  }


  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

img {
    position: relative;
    margin: auto;
    display: flex;
    max-width: 100vw;
    padding: 10px;
}

// MAIN PAGES STYLING

.home {
  min-height: 85vh !important;
}

.hero {
    background: linear-gradient(90deg, $prodFadeLt 0%, $prodFadeDk 50%, $prodFadeLt 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height:500px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem; // might want to move this to an H1 or H2 class
    overflow: hidden;
    min-height: 75vh !important;
}

.iframe-container {
  display: flex; 
  width: 100%; //this enables full screen with in browser
  height: 90vh; //mbedit gives room for footer
  flex-direction: column; 
  //overflow: hidden;
}

.videomain { // For video page
  background: linear-gradient(90deg, $prodFadeLt 0%, $prodFadeDk 50%, $prodFadeLt 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  position: relative;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  justify-content: center;
  //align-items: center; //this kills off the div iframe approach and stops responsiveness in cardmedia approach
}
.videogrid { 
  flex-grow: 1;
  //padding: 8px; // try spacing instead, padding causes overspill to the right on small screens.
  margin: auto; //This is needed to center the video.
  height: 100vh; //Resolves the sizing issue at all display levels. Responsive works when playing, not stopped.
  //The below two settings define the aspect ratio and may need over-riding per video
  max-width: 700px; //this stops it filling the screen on larger displays
  max-height: 480px; //this constrains it from over-stretching in larger displays
}
.vimeogrid { 
  flex-grow: 1;
  background-color: black; //Partial workaround for Vimeo sizing issues compared to YT videos.
  margin: auto; //This is needed to center the video.
  height: 50vh; //Partial workaround for Vimeo sizing issues on mobiles, 100vh causes video background to be too large.
  //The below two settings define the aspect ratio and may need over-riding per video
  max-width: 700px; //this stops it filling the screen on larger displays
  max-height: 480px; //this constrains it from over-stretching in larger displays
}
.vidcard {
  display: flex;
  flex: 1 0 auto;
  //margin: auto;
  transition: 0.3s; 
  box-shadow: 0 8px 40px -12px rgba(0,0,0,0.3);
  &:hover {
    box-shadow: 0 16px 70px -12.125px rgba(0,0,0,0.3);
  }
}
.titles {
  margin: 0 !important;
  padding-bottom: 5px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
  line-height: 1.334 !important;
  letter-spacing: 0em !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.vidimage { //video title image
  //margin: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 100px;
  width: 100px;
}
.bookmain { // For book page
  background: $prodFadeDk;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  position: relative;
  flex-direction: column !important;
  //flex-wrap: nowrap !important;
  justify-content: center;
  //align-items: center; //this kills off the div iframe approach and stops responsiveness in cardmedia approach
}
/*
.bookgrid {
  display: 'flex';
  flex-wrap: 'wrap';
  justify-content: 'space-around';
  overflow: 'hidden';
  //background-color: $prodFadeDk;
}
*/
.gridList {
  width: 500px;
  height: 450px;
  //flex-direction: column;
}
.bookicon {
  color: rgba(225, 228, 234, 0.8) !important;
}
.infopopper {
  border: 1px solid;
  padding: 1px;
  background-color: rgba(225, 228, 234, 0.8);
}
.pagebody { // For pages centered & in one column
  background: linear-gradient(90deg, $prodFadeLt 0%, $prodFadeDk 50%, $prodFadeLt 100%);
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  //flex-wrap: nowrap !important; // needs overspill into footer sorted if I use this.
  min-height: 80vh; 
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}
.mainimg { // Home page top image
    position: relative;
    margin: auto;
    display: flex;
    &:hover {
      box-shadow: 0 16px 70px -12.125px #fbff0d;
      cursor: pointer;
    }
    max-width: 50%;
    padding: 10px;
}
.maintxt { // page body title text- change name to something apropos
    position: relative;
    display: flex;
    text-align: center;
    color: $prodTxt;
}
.adgrid {
    flex-grow: 1;
    padding: 8px;
    background: $myAdsBgd;
}
.adcard {
    max-width: 170px;
    min-width: 100px;
    margin: auto;
    transition: 0.3s; 
    box-shadow: 0 8px 40px -12px rgba(0,0,0,0.3);
    &:hover {
      //box-shadow: 0 16px 70px -12.125px rgba(0,0,0,0.3);
      box-shadow: 0 16px 70px -12.125px #fbff0d;
      cursor: pointer;
    }
}
.admedia {
  padding-top: 60%;
}
.stageplay340 { //stage play images
  //display: block;
  margin: 0 auto; /* Horizontally centers the image */
  height: 340px;
  width: 340px;
}
.stageplay600 { //stage play images
  //display: block;
  margin: 0 auto; /* Horizontally centers the image */
  height: 600px;
  width: 300px;
}
.stageplay640 { ////NOTE: dont use stageplay640 as it causes text wrapping of the page on mobile devices
  //display: block;
  margin: 0 auto; /* Horizontally centers the image */
  height: 384px;
  width: 640px;
}
.topmdkb { //mdkb image in top of page
  margin-bottom: 20px;
  height: 100px;
  width: 100px;
}
.bottommdkb { //mdkb image in top of page
  margin-top: 20px;
  height: 100px;
  width: 100px;
}
.randomTxt { //text title in music page
  color: #fff;
  font-weight: 700;
  font-size: small;
  margin-bottom: 20px;
}
.vumeter { //vu meter at bottom of page
  margin-top: 20px;
  height: 70px;
  width: 70px;
}
.buttons {
  background-color: $myButtonBgd !important;
  color: $myButtonTxt !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  border-bottom: none !important;
  box-shadow: 0 16px 70px -12.125px rgba(0,0,0,0.3) !important;
  &:hover {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12) !important;
    background-color: $prodFadeLt !important;
  }
    &:not(:first-child) {
    margin-Top: 10px;
  }
  &:not(:last-child) {
    border-color: #fff;
  }
}
.faIcon {
  font-size: 1.5em;
  padding: 5px;
}
.faIcon-hover {
  font-size: 1.5em;
  padding: 5px;
  &:hover {
    //box-shadow: 0 16px 70px -12.125px rgba(0,0,0,0.3);
    box-shadow: 0 16px 70px -12.125px #fbff0d;
    cursor: pointer;
  }
}
.root {
    flex-grow: 1;
}

// NAVBAR & FOOTER STYLING
// Needs !important to overide the Material-UI where MUI is set

.header {
  background-color: $myNavBar !important;
  padding-right: 79px;
  padding-left: 118px;
  @media (max-width: 900px) {
    padding-left: 0
  }
}
.footer {
  /*background-color:$myNavBar !important;*/
  margin: auto !important;
  position: relative !important;
  text-align: center; 
  width: 100%; 
}
.footerText {
  /*background-color:$myNavBar !important;*/
  margin: auto !important;
  position: absolute !important;  
  font-family: Open Sans,Arial,sans-serif !important;
  font-weight: 500;
  line-height: 1.7em;
  color: #666;  
  left: 0 !important;
  //bottom: 0 !important; - mbedit using this causes it to float in most windows, but still does it in video window anyway
  right: 0 !important;
}

.logo {
    font-weight: 600 !important;
    color: #FFFEFE;
    text-align: left;
}
.menuButton {
    font-weight: 600 !important;
    font-size: 1em !important;
    margin-left: 38px;
}
.toolbar {
    display: flex;
    justify-content: space-between;
}
.drawerContainer {
    padding: 20px 30px;
}
.paper { // background & text color of mobile nav drawer
  background-color: $myNavDraw !important;
  color: $myNavTxt !important;
}
.drawerMenu { //used to force styling of 'MUImenuItem' in nav drawer
  font-weight: 600 !important;
}
.aboutbody { //centering image top of page
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column !important;
  justify-content: center;  
}
.aboutTitle {
  color: white;
  padding: 20px;
  padding-top: 70px !important;
  font-family: 'Nunito Sans',Helvetica,Arial,Lucida,sans-serif !important;
  line-height: 0.90em !important;
  font-weight: 700 !important;
  font-size: 50px !important;
  -webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.aboutText {
  color: $myNavTxt;
  color: white;
  padding: 25px;
  padding-bottom: 1px;
  max-width: 800px;
  //font-size: 1rem;
  font-family: 'Nunito Sans',Helvetica,Arial,Lucida,sans-serif !important;
  line-height: 1.8em !important; 
  font-weight: 400 !important;
  font-size: 18px !important;
  -webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  text-align: left;
}

.progress-bar::-webkit-slider-thumb { // used by AudioPlayer
  z-index: 4;
  position: relative;
}

.progress-bar::before { // used by AudioPlayer
  transform: scaleX(var(--progress-width));
  z-index: 3;
}

.progress-bar::after { // used by AudioPlayer
  transform: scaleX(var(--buffered-width));
  transform-origin: left;
  z-index: 2;
}

.sampleBg1 { //colour for background of entries in Samples audiobook page (was aboutText-0 but had issues)
  background-color: #330133;
  color: $myNavTxt;
  color: white;
  padding: 25px;
  padding-bottom: 1px;
  max-width: 800px;
  //font-size: 1rem;
  font-family: 'Nunito Sans',Helvetica,Arial,Lucida,sans-serif !important;
  line-height: 1.8em !important; 
  font-weight: 400 !important;
  font-size: 18px !important;
  -webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  text-align: left;
}

.sampleBg2 { //colour for background of entries in Samples audiobook page (was aboutText-0 but had issues)
  background-color: #012906;
  color: $myNavTxt;
  color: white;
  padding: 25px;
  padding-bottom: 1px;
  max-width: 800px;
  //font-size: 1rem;
  font-family: 'Nunito Sans',Helvetica,Arial,Lucida,sans-serif !important;
  line-height: 1.8em !important; 
  font-weight: 400 !important;
  font-size: 18px !important;
  -webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  text-align: left;
}

.sampleBg3 { //colour for background of "Art of Meditation" entries in Samples audiobook page (was aboutText-0 but had issues)
  background-color: #00008B;
  color: $myNavTxt;
  color: #FFFFD4;
  padding: 25px;
  padding-bottom: 1px;
  max-width: 800px;
  //font-size: 1rem;
  font-family: 'Nunito Sans',Helvetica,Arial,Lucida,sans-serif !important;
  line-height: 1.8em !important; 
  font-weight: 400 !important;
  font-size: 18px !important;
  -webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  text-align: left;
}

.purchase-link-0 {
  background-color: #ffe6ff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); // Adjust values as needed
  padding: 5px;
  //color: #fff;
  text-decoration: none;
}